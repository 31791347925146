import React from "react";

interface Props {
  width?: number;
  height?: number;
  color?: string;
}

const EditInline = ({ width, height, color }: Props): JSX.Element => (
  <svg
    width={width ?? "16"}
    height={height ?? "16"}
    viewBox={`0 0 ${width ?? "16"} ${height ?? "16"}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="edit-3">
      <g id="Icon">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.33351 13.3333C7.33351 12.9651 7.63199 12.6667 8.00018 12.6667H14.0002C14.3684 12.6667 14.6668 12.9651 14.6668 13.3333C14.6668 13.7015 14.3684 14 14.0002 14H8.00018C7.63199 14 7.33351 13.7015 7.33351 13.3333Z"
          fill={color ?? "#8593A3"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.0002 2.58579C11.8019 2.58579 11.6118 2.66455 11.4716 2.80474L3.26882 11.0075L2.91642 12.4171L4.32601 12.0647L12.5288 3.86193C12.5982 3.79252 12.6533 3.71011 12.6908 3.61941C12.7284 3.52871 12.7477 3.43151 12.7477 3.33334C12.7477 3.23517 12.7284 3.13796 12.6908 3.04726C12.6533 2.95657 12.5982 2.87416 12.5288 2.80474C12.4594 2.73533 12.3769 2.68026 12.2863 2.64269C12.1956 2.60513 12.0983 2.58579 12.0002 2.58579ZM10.5288 1.86193C10.919 1.47169 11.4483 1.25246 12.0002 1.25246C12.2734 1.25246 12.544 1.30628 12.7965 1.41085C13.049 1.51543 13.2784 1.6687 13.4716 1.86193C13.6648 2.05516 13.8181 2.28455 13.9227 2.53702C14.0272 2.78948 14.0811 3.06007 14.0811 3.33334C14.0811 3.6066 14.0272 3.87719 13.9227 4.12966C13.8181 4.38212 13.6648 4.61151 13.4716 4.80474L5.13825 13.1381C5.05281 13.2235 4.94576 13.2841 4.82853 13.3134L2.16187 13.9801C1.93468 14.0369 1.69436 13.9703 1.52877 13.8047C1.36319 13.6392 1.29662 13.3988 1.35342 13.1716L2.02008 10.505C2.04939 10.3878 2.11 10.2807 2.19544 10.1953L10.5288 1.86193Z"
          fill={color ?? "#8593A3"}
        />
      </g>
    </g>
  </svg>
);

export default EditInline;
