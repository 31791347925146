import React from "react";

interface Props {
  width?: number;
  height?: number;
  color?: string;
}

const MenuIcon = ({ width, height, color }: Props): JSX.Element => (
  <svg
    width={width ?? "16"}
    height={height ?? "16"}
    viewBox={`0 0 ${width ?? "16"} ${height ?? "16"}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="icon-placeholder">
      <g id="Shape">
        <path
          d="M3.3335 1.33333C2.80306 1.33333 2.29436 1.54404 1.91928 1.91911C1.54421 2.29419 1.3335 2.8029 1.3335 3.33333V5.33333C1.3335 5.70152 1.63197 6 2.00016 6C2.36835 6 2.66683 5.70152 2.66683 5.33333V3.33333C2.66683 3.15652 2.73707 2.98695 2.86209 2.86192C2.98712 2.7369 3.15668 2.66666 3.3335 2.66666H5.3335C5.70169 2.66666 6.00016 2.36818 6.00016 1.99999C6.00016 1.63181 5.70169 1.33333 5.3335 1.33333H3.3335Z"
          fill={color ?? "#00B596"}
        />
        <path
          d="M10.6668 1.33333C10.2986 1.33333 10.0002 1.63181 10.0002 1.99999C10.0002 2.36818 10.2986 2.66666 10.6668 2.66666H12.6668C12.8436 2.66666 13.0132 2.7369 13.1382 2.86192C13.2633 2.98695 13.3335 3.15652 13.3335 3.33333V5.33333C13.3335 5.70152 13.632 6 14.0002 6C14.3684 6 14.6668 5.70152 14.6668 5.33333V3.33333C14.6668 2.80289 14.4561 2.29419 14.081 1.91911C13.706 1.54404 13.1973 1.33333 12.6668 1.33333H10.6668Z"
          fill={color ?? "#00B596"}
        />
        <path
          d="M2.66683 10.6667C2.66683 10.2985 2.36835 10 2.00016 10C1.63197 10 1.3335 10.2985 1.3335 10.6667V12.6667C1.3335 13.1971 1.54421 13.7058 1.91928 14.0809C2.29436 14.4559 2.80306 14.6667 3.3335 14.6667H5.3335C5.70169 14.6667 6.00016 14.3682 6.00016 14C6.00016 13.6318 5.70169 13.3333 5.3335 13.3333H3.3335C3.15669 13.3333 2.98712 13.2631 2.86209 13.1381C2.73707 13.013 2.66683 12.8435 2.66683 12.6667V10.6667Z"
          fill={color ?? "#00B596"}
        />
        <path
          d="M14.6668 10.6667C14.6668 10.2985 14.3684 10 14.0002 10C13.632 10 13.3335 10.2985 13.3335 10.6667V12.6667C13.3335 12.8435 13.2633 13.013 13.1382 13.1381C13.0132 13.2631 12.8436 13.3333 12.6668 13.3333H10.6668C10.2986 13.3333 10.0002 13.6318 10.0002 14C10.0002 14.3682 10.2986 14.6667 10.6668 14.6667H12.6668C13.1973 14.6667 13.706 14.4559 14.081 14.0809C14.4561 13.7058 14.6668 13.1971 14.6668 12.6667V10.6667Z"
          fill={color ?? "#00B596"}
        />
      </g>
    </g>
  </svg>
);

export default MenuIcon;
